import { doc, getDoc } from 'firebase/firestore'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { Document } from '../types'
import { db } from '../pages/_app'

export function useDoc(id?: string) {
  const getter = useCallback(async () => {
    if (!id) throw new Error()
    const docRef = doc(db, 'documents', id)
    const docSnap = await getDoc(docRef).catch(() => null)

    if (docSnap && docSnap.exists()) {
      return { id, ...docSnap.data() } as Document
    } else {
      return { id } as Document
    }
  }, [id])
  return useQuery(['documents', id], getter, {
    enabled: !!id,
  })
}
