import { FirebaseError } from 'firebase/app'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { memo, useEffect } from 'react'
import { useWorkspaceBySlug } from '../data/useWorkspace'
import { ErrorScreen } from '../pageComponents/ErrorScreen'
import { LoadingScreen } from '../pageComponents/LoadingScreen'
import { Workspace } from '../pageComponents/Workspace'
import { WorkspaceDoc } from '../pageComponents/WorkspaceDoc'
import { findInTree, pathForId } from '../utils/utils'

const WorkspaceId: NextPage = memo(() => {
  const router = useRouter()
  const { data, error } = useWorkspaceBySlug(router.query.path?.[0] as string)

  useEffect(() => {
    if (data?.homepageId && data?.tree) {
      const { index, childIndex } = findInTree(data.tree, data.homepageId)
      if (index === -1) return
      const node =
        childIndex === -1
          ? data.tree[index]
          : data.tree[index].childNodes?.[childIndex]
      if (!node) return
      void router.replace(
        `/${data.slug || data.id}/${pathForId(data.tree, node.id)}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id])

  // TODO: cast to FirebaseError is incorrect, fix this
  if (error) return <ErrorScreen message={(error as FirebaseError).message} />
  if (!data) return <LoadingScreen />
  return (
    <Workspace workspace={data}>
      {(
        isEditable: boolean,
        onMove: () => void,
        onUpdate: (obj: object) => Promise<void>,
        onDelete: () => void
      ) => (
        <WorkspaceDoc
          workspaceName={data.name}
          workspaceSlug={data.slug || data.id}
          workspaceCodeExample={data.codeExample}
          workspaceCodeLanguage={data.codeLanguage}
          isEditable={isEditable}
          tree={data.tree}
          onMove={onMove}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      )}
    </Workspace>
  )
})

WorkspaceId.displayName = 'WorkspaceId'
export default WorkspaceId
