import {
  Button,
  Tree,
  Icon,
  InputGroup,
  Dialog,
  HTMLSelect,
  ContextMenu,
  Menu,
  MenuDivider,
  MenuItem,
  TreeNodeInfo,
  Alert,
  EditableText,
  Switch,
  TextArea,
} from '@blueprintjs/core'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  deleteUser,
  getAuth,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  signOut,
  TwitterAuthProvider,
} from 'firebase/auth'
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import { v4 } from 'uuid'
import getSlug from 'speakingurl'
import Head from 'next/head'
import { FirebaseError } from 'firebase/app'
import { NodeData, Workspace as WorkspaceType, WorkspaceNode } from '../types'
import { AppToaster } from '../components/AppToaster'
import { useUpdate } from '../data/useUpdate'
import {
  addToTree,
  copyLink,
  findInTree,
  getCollapsedFolderIds,
  isSelected,
  mapNode,
  mapTree,
  moveInTree,
  pathForId,
  removeFromTree,
  renameInTree,
} from '../utils/utils'
import { useCreate, useCreateDoc } from '../data/useCreate'
import { Clickable } from './Clickable'

export interface WorkspaceProps {
  workspace: WorkspaceType
  children: (
    isEditable: boolean,
    onMove: () => void,
    onUpdate: (obj: object) => Promise<void>,
    onDelete: () => void
  ) => void
}

export const Workspace: React.FC<WorkspaceProps> = React.memo(
  ({ workspace, children }: WorkspaceProps) => {
    const auth = getAuth()
    const [user] = useAuthState(auth)
    const create = useCreate()
    const createDocFn = useCreateDoc()
    const update = useUpdate()
    const [collapsedFolderIds, setCollapsedFolderIds] = useState(
      getCollapsedFolderIds()
    )
    useEffect(() => {
      window.localStorage.setItem(
        'whatfields-collapsed-folders',
        JSON.stringify(collapsedFolderIds)
      )
    }, [collapsedFolderIds])
    const [modal, setModal] = useState<
      | 'folder'
      | 'doc'
      | 'move'
      | 'rename'
      | 'delete'
      | 'delete-account'
      | 'account'
      | 'feedback'
      | false
    >(false)
    const [folderId, setFolderId] = useState<string>('')
    const [inputName, setInputName] = useState('')
    const [activeNode, setActiveNode] = useState<TreeNodeInfo | undefined>(
      undefined
    )
    const folders = useMemo(
      () => workspace.tree?.filter((node) => node.type === 'folder'),
      [workspace.tree]
    )
    const docs = useMemo(
      () => workspace.tree?.filter((node) => node.type === 'document'),
      [workspace.tree]
    )
    // TODO: Duplicated code
    const router = useRouter()
    const path = useMemo(
      () =>
        Array.isArray(router.query.path)
          ? router.query.path.slice(1)
          : router.query.path
          ? [router.query.path]
          : [],
      [router.query.path]
    )

    useEffect(() => {
      if (!modal) {
        setInputName('')
        setFolderId('')
        setActiveNode(undefined)
      }
    }, [modal])

    const mappedTree = useMemo(
      () => mapTree(path, workspace.tree || [], collapsedFolderIds),
      [collapsedFolderIds, path, workspace.tree]
    )

    const selectedNode = useMemo(() => {
      let found, topName, topId
      if (workspace.tree) {
        for (const item of workspace.tree) {
          if (item.type === 'document' && isSelected(path, item)) {
            found = item
            break
          }
          if (item.childNodes) {
            const foundChild = item.childNodes.find((child) =>
              isSelected(path, child, item.name, item.id)
            )
            if (foundChild) {
              found = foundChild
              topName = item.name
              topId = item.id
              break
            }
          }
        }
      }
      if (found) {
        return mapNode(path, found, collapsedFolderIds, topName, topId)
      }
    }, [collapsedFolderIds, path, workspace.tree])

    const [isShareCollapsed, setIsShareCollapsed] = useState(
      typeof window !== 'undefined'
        ? window.localStorage.getItem('whatfields-share-collapsed') === 'true'
        : false
    )

    useEffect(() => {
      window.localStorage.setItem(
        'whatfields-share-collapsed',
        isShareCollapsed ? 'true' : 'false'
      )
    }, [isShareCollapsed])

    const rename = useCallback(
      (e: FormEvent) => {
        e.preventDefault()
        if (!inputName) {
          return AppToaster.show({
            message: 'Enter a page name first',
            intent: 'danger',
          })
        }
        void update(
          'workspaces',
          workspace.id,
          {
            tree: renameInTree(
              workspace.tree as WorkspaceNode[],
              activeNode?.id as string,
              inputName
            ),
          },
          ['workspace', workspace.slug || workspace.id]
        ).then(() => {
          setModal(false)
        })
      },
      [activeNode?.id, inputName, update, workspace.id, workspace.tree]
    )

    const [homepage, setHomepage] = useState(workspace.homepageId)

    const move = useCallback(
      (e: FormEvent) => {
        e.preventDefault()
        const tree = moveInTree(
          workspace.tree as WorkspaceNode[],
          activeNode?.id as string,
          folderId
        )
        void update(
          'workspaces',
          workspace.id,
          {
            tree,
          },
          ['workspace', router.query.path?.[0] as string]
        ).then(() => {
          void router.replace(
            `/${workspace.slug || workspace.id}/${pathForId(
              tree,
              activeNode?.id as string
            )}`
          )
          setModal(false)
        })
      },
      [
        activeNode?.id,
        folderId,
        router,
        update,
        workspace.id,
        workspace.slug,
        workspace.tree,
      ]
    )

    const createDoc = useCallback(async () => {
      if (!inputName) {
        return AppToaster.show({
          message: 'Enter a page name first',
          intent: 'danger',
        })
      }
      const newSlug = getSlug(inputName)
      if (folderId) {
        if (
          workspace.tree
            ?.find((node) => node.id === folderId)
            ?.childNodes?.some(
              (node) => node.name && getSlug(node.name) === newSlug
            )
        ) {
          return AppToaster.show({
            message: 'Page with this name already exists',
            intent: 'danger',
          })
        }
      } else {
        if (
          workspace.tree?.some(
            (node) => node.name && getSlug(node.name) === newSlug
          )
        ) {
          return AppToaster.show({
            message: 'Page with this name already exists',
            intent: 'danger',
          })
        }
      }
      const id = await createDocFn()
      if (typeof id !== 'string') throw new Error('Could not create page')
      const tree = addToTree(
        workspace.tree,
        'document',
        id,
        inputName,
        folderId
      )
      void update(
        'workspaces',
        workspace.id,
        {
          tree,
        },
        ['workspace', router.query.path?.[0] as string]
      ).then(() => {
        void router.push(
          `/${workspace.slug || workspace.id}/${pathForId(tree, id)}?e=1`
        )
        setModal(false)
      })
    }, [
      createDocFn,
      folderId,
      inputName,
      router,
      update,
      workspace.id,
      workspace.tree,
    ])

    const remove = useCallback(() => {
      void update(
        'workspaces',
        workspace.id,
        {
          tree: removeFromTree(
            workspace.tree as WorkspaceNode[],
            (activeNode as TreeNodeInfo).id as string
          ),
        },
        ['workspace', router.query.path?.[0] as string]
      ).then(() => {
        setModal(false)
        const isOpen =
          selectedNode && activeNode && activeNode.id === selectedNode.id
        if (isOpen) void router.push(`/${workspace.slug || workspace.id}`)
      })
    }, [activeNode, router, selectedNode, update, workspace.id, workspace.tree])

    const createFolder = useCallback(
      (e: FormEvent) => {
        e.preventDefault()
        if (!inputName) {
          return AppToaster.show({
            message: 'Enter a folder name first',
            intent: 'danger',
          })
        }
        void update(
          'workspaces',
          workspace.id,
          {
            tree: [
              ...(workspace.tree || []),
              {
                id: v4(),
                type: 'folder',
                name: inputName,
              } as WorkspaceNode,
            ],
          },
          ['workspace', router.query.path?.[0] as string]
        ).then(() => {
          setModal(false)
        })
      },
      [inputName, router.query.path, update, workspace.id, workspace.tree]
    )

    const maybeDocs = useMemo(() => {
      return workspace.name?.toLowerCase().endsWith('docs') ? '' : 'Docs'
    }, [workspace.name])

    const [isPreview, setIsPreview] = useState(false)
    const isEditableWithoutPreview = useMemo(() => {
      return user?.uid && workspace.id === user.uid
    }, [user?.uid, workspace.id])
    const isEditable = useMemo(() => {
      return !isPreview && !!isEditableWithoutPreview
    }, [isEditableWithoutPreview, isPreview])

    return (
      <div className="flex h-screen flex-col overflow-hidden">
        <Head>
          <title>
            {workspace.name} {maybeDocs}
          </title>
        </Head>
        <div className="flex h-full flex-1 overflow-hidden">
          <aside className="hidden w-[320px] flex-shrink-0 flex-col justify-start overflow-hidden border-r border-t border-slate-200 bg-slate-50 md:flex">
            <div className="flex-shrink-0 py-6 px-6 pb-4">
              <div className="flex items-end justify-between">
                <h4 className="px-1 font-medium text-slate-500">
                  What fields has
                </h4>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/jeroenmakes/status/1530842623715549184"
                  className="inline-block bg-orange-100 px-1.5 py-0.5 text-xs font-medium text-orange-500 hover:text-orange-500"
                >
                  V0.2 alpha
                </a>
              </div>
              <div className="flex items-center justify-between">
                <div className="mr-2 truncate p-1">
                  <EditableText
                    disabled={!isEditable}
                    placeholder="Add Title"
                    className="h1 text-2xl font-black tracking-tight text-slate-600"
                    defaultValue={workspace.name}
                    onConfirm={(name) =>
                      void update('workspaces', workspace.id, { name }, [
                        'workspace',
                        router.query.path?.[0] as string,
                      ])
                    }
                  />
                </div>
              </div>
            </div>
            {isEditable && (
              <div className="-mx-1 mb-4 flex flex-shrink-0 space-x-1 border-t border-b py-1 px-6">
                <Tooltip2
                  content={
                    <span className="block w-56 text-xs">
                      Use <span className="font-bold">folders</span> to
                      differentiate APIs, databases, environments, versions, or
                      whatever works for your project.
                    </span>
                  }
                  placement="bottom-start"
                >
                  <Button
                    minimal
                    onClick={() => setModal('folder')}
                    icon="folder-new"
                  >
                    Folder
                  </Button>
                </Tooltip2>
                <Tooltip2
                  content={
                    <span className="block w-56 text-xs">
                      Use <span className="font-bold">pages</span> to specify a
                      set of fields. You can use them to represent collections,
                      tables, endpoints, types or whatever works for your
                      project.
                    </span>
                  }
                  placement="bottom-start"
                >
                  <Button minimal icon="add" onClick={() => setModal('doc')}>
                    Page
                  </Button>
                </Tooltip2>
                <Tooltip2
                  className="!ml-auto"
                  content={
                    <span className="block w-40 text-xs">
                      Feature request or found a bug? Open the{' '}
                      <span className="font-bold">feedback box</span> to let me
                      know.
                    </span>
                  }
                  placement="bottom-start"
                >
                  <Button
                    minimal
                    onClick={() => setModal('feedback')}
                    icon="chat"
                  />
                </Tooltip2>
                <Tooltip2
                  content={
                    <span className="block w-40 text-xs">
                      Open your docs &amp; account{' '}
                      <span className="font-bold">settings</span> by clicking
                      here.
                    </span>
                  }
                  placement="bottom-start"
                >
                  <Button
                    minimal
                    onClick={() => setModal('account')}
                    icon="settings"
                  />
                </Tooltip2>
              </div>
            )}
            <div className="flex-1 overflow-scroll pb-4">
              {mappedTree ? (
                <Tree
                  onNodeContextMenu={(node, path, e) => {
                    e.preventDefault()
                    // TODO Depreciated, migrate to ContextMenu2
                    ContextMenu.show(
                      <Menu>
                        <MenuItem
                          disabled={true}
                          text={node.label || 'Unnamed'}
                        />
                        <MenuDivider />
                        {node.icon === 'document' && (
                          <MenuItem
                            icon="document-open"
                            text="Move"
                            onClick={() => {
                              setModal('move')
                              setFolderId(
                                (node.nodeData as NodeData)?.topId || ''
                              )
                              setActiveNode(node)
                            }}
                          />
                        )}
                        <MenuItem
                          icon="edit"
                          text="Rename"
                          onClick={() => {
                            setModal('rename')
                            setInputName((node.label as string) || '')
                            setActiveNode(node)
                          }}
                        />
                        <MenuItem
                          icon="trash"
                          text="Delete"
                          onClick={() => {
                            setModal('delete')
                            setActiveNode(node)
                          }}
                        />
                      </Menu>,
                      { left: e.clientX, top: e.clientY }
                    )
                  }}
                  onNodeClick={(node) => {
                    const { nodeData, label, icon, id } = node
                    if (icon === 'folder-close') {
                      const collapsedFolderIdsNew = [...collapsedFolderIds]
                      if (collapsedFolderIdsNew.includes(id as string)) {
                        const index = collapsedFolderIdsNew.indexOf(
                          id as string
                        )
                        collapsedFolderIdsNew.splice(index, 1)
                      } else {
                        collapsedFolderIdsNew.push(id as string)
                      }
                      setCollapsedFolderIds(collapsedFolderIdsNew)
                      return
                    }
                    const topName = getSlug(
                      (nodeData as NodeData).topName || ''
                    )
                    const labelName = getSlug(label as string) || (id as string)
                    if (topName) {
                      void router.push(
                        `/${
                          workspace.slug || workspace.id
                        }/${topName}/${labelName}`
                      )
                    } else {
                      void router.push(
                        `/${workspace.slug || workspace.id}/${labelName}`
                      )
                    }
                  }}
                  contents={mappedTree}
                />
              ) : (
                <div className="flex flex-col items-center justify-center py-10 text-center text-slate-500">
                  <Icon icon="folder-open" className="mb-2 text-slate-400" />
                  No folders or pages.
                </div>
              )}
            </div>
            {isEditableWithoutPreview ? (
              <div className="m-4 my-4 mt-auto">
                {isPreview && (
                  <Button
                    className="mb-4"
                    outlined
                    minimal
                    fill
                    icon="user"
                    disabled
                  >
                    Log in
                  </Button>
                )}
                <div className="rounded-xl bg-purple-100 px-4 pt-3 pb-1.5 ring-offset-2">
                  {!isShareCollapsed ? (
                    <Clickable onPress={() => setIsShareCollapsed(true)}>
                      <div className="cursor-default pb-4 text-sm font-medium text-slate-700">
                        <div className="mb-2 flex justify-between">
                          <h4 className="pt-2 text-purple-400">Public</h4>
                          <Icon
                            icon="minus"
                            intent="primary"
                            className="!text-purple-400"
                          />
                        </div>
                        This page is{' '}
                        <span className="border-b-2 border-purple-300">
                          viewable by everyone
                        </span>{' '}
                        who has the link. You are the only one who can make
                        edits, though!
                      </div>
                    </Clickable>
                  ) : (
                    <Clickable onPress={() => setIsShareCollapsed(false)}>
                      <div className="-mt-1 cursor-default pb-1.5 text-center">
                        <Icon
                          icon="chevron-up"
                          intent="primary"
                          className="!text-purple-400"
                        />
                      </div>
                    </Clickable>
                  )}
                  <Button
                    onClick={() => copyLink(workspace.slug || workspace.id)}
                    icon="link"
                    className="w-full !rounded !bg-purple-500 !text-white"
                  >
                    Copy link
                  </Button>
                  <div className="mt-0.5 flex items-center justify-center space-x-4">
                    <label htmlFor="preview" className="text-xs font-medium">
                      Preview as visitor
                    </label>
                    <Switch
                      id="preview"
                      className="mt-2.5"
                      checked={isPreview}
                      onChange={() => setIsPreview(!isPreview)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-4">
                {user ? (
                  <Button
                    onClick={() => void router.push('/')}
                    outlined
                    minimal
                    fill
                    icon={
                      user?.photoURL ? (
                        <div
                          className="h-5 w-5 rounded-full bg-slate-300 bg-cover bg-center"
                          style={{ backgroundImage: `url(${user.photoURL})` }}
                        />
                      ) : (
                        'user'
                      )
                    }
                  >
                    {user.displayName?.split(' ')[0] || 'Account'}
                  </Button>
                ) : (
                  <Button
                    onClick={() => void router.push('/')}
                    outlined
                    minimal
                    fill
                    icon="user"
                  >
                    Log in
                  </Button>
                )}
              </div>
            )}
          </aside>
          <main id="main" className="relative flex-1 overflow-scroll">
            {children(
              isEditable,
              () => {
                setModal('move')
                setFolderId((selectedNode?.nodeData as NodeData)?.topId || '')
                setActiveNode(selectedNode)
              },
              (obj: object) =>
                update('workspaces', workspace.id, obj, [
                  'workspaces',
                  workspace.id,
                ]),
              () => {
                setModal('delete')
                setActiveNode(selectedNode)
              }
            )}
            <Dialog
              isOpen={modal === 'folder'}
              onClose={() => setModal(false)}
              title="Folder"
              icon="folder-new"
            >
              <form onSubmit={createFolder} className="p-5 pb-0">
                <p className="mb-6">
                  Create a <span className="font-semibold">folder</span> to
                  differentiate APIs, databases, environments, versions, or
                  whatever works for your project.
                </p>
                <InputGroup
                  value={inputName}
                  onChange={(e) => setInputName(e.target.value)}
                  large
                  autoFocus
                  placeholder="Folder name..."
                />
                <p className="mt-2 mb-2 text-xs">
                  You can always change this later!
                </p>
                <Button
                  large
                  type="submit"
                  text="Create"
                  intent="primary"
                  className="mt-4 w-full text-center"
                />
              </form>
            </Dialog>
            <Dialog
              isOpen={modal === 'doc'}
              onClose={() => setModal(false)}
              title="Page"
              icon="add"
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  void createDoc()
                }}
                className="p-5 pb-0"
              >
                <p className="mb-6">
                  Create a <span className="font-semibold">page</span> to
                  specify a set of fields. You can use them to represent
                  collections, tables, endpoints, object types or whatever works
                  for your project.
                </p>
                <InputGroup
                  value={inputName}
                  onChange={(e) => setInputName(e.target.value)}
                  large
                  autoFocus
                  placeholder="Page name..."
                />
                <p className="mt-2 mb-2 text-xs">
                  You can always change this later!
                </p>
                <div className="mt-4 mb-2 font-semibold">Create in folder</div>
                <HTMLSelect
                  value={folderId}
                  onChange={(e) => setFolderId(e.target.value)}
                  large
                  className="mb-2 w-full"
                >
                  <option key="no-folder" value="">
                    No folder
                  </option>
                  {folders?.map((folder) => (
                    <option key={folder.id} value={folder.id}>
                      {folder.name || 'Unnamed folder'}
                    </option>
                  ))}
                </HTMLSelect>
                <Button
                  large
                  type="submit"
                  text="Create"
                  intent="primary"
                  className="mt-4 w-full text-center"
                />
              </form>
            </Dialog>
            <Dialog
              isOpen={modal === 'rename'}
              onClose={() => setModal(false)}
              title="Rename"
              icon="edit"
            >
              <form onSubmit={rename} className="p-5 pb-0">
                <InputGroup
                  value={inputName}
                  onChange={(e) => setInputName(e.target.value)}
                  large
                  autoFocus
                  placeholder="Rename to..."
                />
                <Button
                  large
                  type="submit"
                  text="Rename"
                  intent="primary"
                  className="mt-4 w-full text-center"
                />
              </form>
            </Dialog>
            <Dialog
              isOpen={modal === 'move'}
              onClose={() => setModal(false)}
              title="Move"
              icon="add-to-folder"
            >
              <form onSubmit={move} className="p-5 pb-0">
                <HTMLSelect
                  value={folderId}
                  onChange={(e) => setFolderId(e.target.value)}
                  large
                  className="mb-2 w-full"
                >
                  <option key="no-folder" value="">
                    No folder
                  </option>
                  {folders?.map((folder) => (
                    <option key={folder.id} value={folder.id}>
                      {folder.name || 'Unnamed folder'}
                    </option>
                  ))}
                </HTMLSelect>
                <Button
                  large
                  type="submit"
                  text="Move"
                  intent="primary"
                  className="mt-4 w-full text-center"
                />
              </form>
            </Dialog>
            <Alert
              isOpen={modal === 'delete'}
              onClose={() => setModal(false)}
              icon="trash"
              cancelButtonText="Cancel"
              confirmButtonText="Delete"
              intent="danger"
              onConfirm={remove}
              canEscapeKeyCancel
              canOutsideClickCancel
            >
              <p>
                Are you sure you want to remove{' '}
                <span className="font-semibold">{activeNode?.label || ''}</span>
                ?{' '}
                {activeNode?.isExpanded
                  ? 'Its contents will be removed permanently.'
                  : 'It will be removed permanently.'}
              </p>
            </Alert>
            <Alert
              isOpen={modal === 'delete-account'}
              onClose={() => setModal(false)}
              icon="warning-sign"
              cancelButtonText="Cancel"
              confirmButtonText="Sign in again to delete account"
              intent="danger"
              onConfirm={(e) => {
                if (!user) return
                const provider =
                  user.providerData[0].providerId ===
                  TwitterAuthProvider.PROVIDER_ID
                    ? new TwitterAuthProvider()
                    : new GoogleAuthProvider()
                reauthenticateWithPopup(user, provider)
                  .then(() => {
                    void deleteUser(user).then(() => {
                      void router.push('/')
                      AppToaster.show({
                        message:
                          'Your account has been deleted. Thanks for trying the app! 👋',
                      })
                    })
                  })
                  .catch((error) => {
                    AppToaster.show({
                      intent: 'danger',
                      message: (error as FirebaseError).message,
                    })
                  })
              }}
              canEscapeKeyCancel
              canOutsideClickCancel
            >
              <p className="font-medium">
                Are you sure you want to delete your account and all of your
                docs?
              </p>
              <p className="my-3 text-xs leading-normal">
                This will remove your documentation website and any pages you
                have created forever. Other users may register your link. You
                can register again later, but cannot restore any of your
                documents.
              </p>
            </Alert>
            <Dialog
              isOpen={modal === 'account'}
              onClose={() => setModal(false)}
              title="Settings"
              icon="settings"
            >
              <div className="space-y-6 p-5 pb-0">
                <div className="flex items-center space-x-3">
                  <Icon
                    size={40}
                    color="lightGray"
                    icon={
                      user?.photoURL ? (
                        <div
                          className="h-10 w-10 rounded-full bg-slate-300 bg-cover bg-center"
                          style={{
                            backgroundImage: `url(${user?.photoURL || ''})`,
                          }}
                        />
                      ) : (
                        'user'
                      )
                    }
                  />
                  <div>
                    <h2 className="text-2xl">{user?.displayName}</h2>
                    <div className="-mt-1 text-slate-500">{user?.email}</div>
                  </div>
                </div>
                <div className="space-y-6 text-sm">
                  <p>
                    Hey {user?.displayName?.split(' ')[0]}! Thanks for using
                    WhatFields!
                  </p>
                  <h4>Sharable link</h4>
                  <div className="!mt-1 flex">
                    <InputGroup
                      className="flex-1"
                      leftElement={
                        <div className="pt-[5px] pl-3 text-slate-400">
                          whatfields.com/
                        </div>
                      }
                      value={workspace.slug || workspace.id}
                      readOnly
                    />
                    <Tooltip2 content="Copy link">
                      <Button
                        icon="link"
                        className="ml-1"
                        onClick={() => copyLink(workspace.slug || workspace.id)}
                      />
                    </Tooltip2>
                  </div>
                  <p className="!mt-2 text-xs">
                    You can currently only set your docs link when you create
                    it. If you need to change it, please contact me using the
                    feedback button and I&apos;ll fix it manually.
                  </p>
                  <h4>Homepage</h4>
                  <HTMLSelect
                    value={homepage}
                    onChange={(e) => {
                      setHomepage(e.target.value)
                      void update(
                        'workspaces',
                        workspace.id,
                        {
                          homepageId: e.target.value,
                        },
                        ['workspace', workspace.slug || workspace.id]
                      )
                    }}
                    large
                    className="!mt-1 mb-2 w-full"
                  >
                    <option key="" value="">
                      Empty page
                    </option>
                    {workspace.tree?.map((document) => (
                      <React.Fragment key={document.id}>
                        <option
                          disabled={document.type === 'folder'}
                          key={document.id}
                          value={document.id}
                        >
                          {document.name || `Unnamed ${document.type}`}
                        </option>
                        {document.childNodes
                          ?.sort((a, b) =>
                            (a.name || 'Unnamed').localeCompare(
                              b.name || 'Unnamed'
                            )
                          )
                          .sort((a, b) =>
                            a.type === 'folder'
                              ? -1
                              : b.type !== 'folder'
                              ? 1
                              : 0
                          )
                          .map((child) => (
                            <option key={child.id} value={child.id}>
                              &mdash; {child.name || 'Unnamed document'}
                            </option>
                          ))}
                      </React.Fragment>
                    ))}
                  </HTMLSelect>
                  <h4>Private docs</h4>
                  <p className="!mt-1">
                    If you&apos;d like to use WhatFields for private docs (e.g.
                    for within your team), please let me know using the feedback
                    button! We don&apos;t currently support it but might if
                    there is some interest.
                  </p>
                  <h4>Looking for anything?</h4>
                  <p className="!mt-1">
                    There isn&apos;t much more you can do here (yet). If you
                    need something else, please send me a message using the
                    feedback button.
                  </p>
                </div>
                <div className="flex space-x-5">
                  <Button
                    icon="log-out"
                    onClick={() => {
                      void router.push('/')
                      void signOut(auth)
                      AppToaster.show({
                        message: "You're signed out. Bye! 👋",
                      })
                    }}
                  >
                    Sign Out
                  </Button>
                  <div className="flex-1" />
                  <Popover2
                    content={
                      <Menu>
                        <a
                          href="//twitter.com/jeroenmakes"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <MenuItem
                            icon="link"
                            text="Follow me on Twitter :)"
                          />
                        </a>
                        <MenuDivider />
                        <MenuItem
                          onClick={() => setModal('delete-account')}
                          intent="danger"
                          icon="warning-sign"
                          text="Delete account"
                        />
                      </Menu>
                    }
                    position="bottom-left"
                  >
                    <Button className="px-4">
                      <Icon icon="more" />
                    </Button>
                  </Popover2>
                </div>
              </div>
            </Dialog>
            <Dialog
              isOpen={modal === 'feedback'}
              onClose={() => setModal(false)}
              title="Feedback"
              icon="chat"
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  if (!inputName) {
                    AppToaster.show({
                      message: 'Enter some feedback first',
                      intent: 'danger',
                    })
                    return
                  }
                  void create('feedback', {
                    message: inputName,
                    userId: user?.uid,
                    userEmail: user?.email,
                    createdOn: new Date().toISOString(),
                  })
                    .then(() => {
                      AppToaster.show({ message: 'Feedback sent! Thanks!' })
                      setModal(false)
                    })
                    .catch((e) => {
                      AppToaster.show({
                        message: (e as FirebaseError).message,
                        intent: 'danger',
                      })
                    })
                }}
                className="p-5 pb-0"
              >
                <TextArea
                  autoFocus
                  placeholder="Type some feedback here..."
                  large
                  rows={6}
                  fill
                  value={inputName}
                  onChange={(e) => setInputName(e.target.value)}
                  className="!resize-none"
                />
                <p className="mx-auto mt-2 mb-4 px-6 text-center text-xs text-slate-500">
                  Your email address ({user?.email || ''}) will be sent with
                  your message so that I can contact you if needed.
                </p>
                <Button type="submit" large fill intent="primary">
                  Send
                </Button>
              </form>
            </Dialog>
          </main>
        </div>
      </div>
    )
  }
)

Workspace.displayName = 'Workspace'
