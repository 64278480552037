import { doc, FirestoreError, updateDoc } from 'firebase/firestore'
import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { AppToaster } from '../components/AppToaster'
import { db } from '../pages/_app'

export function useUpdate() {
  const queryClient = useQueryClient()
  return useCallback(
    async (
      collection: string,
      id: string,
      obj: object,
      queryName?: string | string[]
    ) => {
      const ref = doc(db, collection, id)
      return updateDoc(ref, obj)
        .then(() => {
          if (queryName) void queryClient.invalidateQueries(queryName)
        })
        .catch((e: FirestoreError) => {
          AppToaster.show({ message: e.message, intent: 'danger' })
        })
    },
    [queryClient]
  )
}
