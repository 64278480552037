import {
  Breadcrumbs,
  Button,
  EditableText,
  NonIdealState,
  IBreadcrumbProps,
  Icon,
  Menu,
  MenuItem,
  MenuDivider,
} from '@blueprintjs/core'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { FirebaseError } from 'firebase/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dynamic from 'next/dynamic'
import { Select2 } from '@blueprintjs/select'
import { TextareaCodeEditorProps } from '@uiw/react-textarea-code-editor'
import { useDoc } from '../data/useDoc'
import { useUpdate } from '../data/useUpdate'
import { Field, WorkspaceNode } from '../types'
import '@uiw/react-textarea-code-editor/dist.css'
import {
  copyLink,
  getNodeForSlug,
  pathForId,
  renameInTree,
} from '../utils/utils'
import { AppToaster } from '../components/AppToaster'
import { ErrorScreen } from './ErrorScreen'
import { Fields } from './Fields'
import { LoadingScreen } from './LoadingScreen'

const SUPPORTED_LANGUAGES = [
  'Arduino',
  'Bash',
  'Basic',
  'C',
  'C-like',
  'CPP',
  'C#',
  'CSS',
  'Diff',
  'Go',
  'INI',
  'Java',
  'JavaScript',
  'JSON',
  'Kotlin',
  'Less',
  'Lua',
  'Makefile',
  'Markdown',
  'Markup',
  'Markup Templating',
  'Objective-C',
  'Perl',
  'PHP',
  'Python',
  'R',
  'Regex',
  'Ruby',
  'Rust',
  'Sass',
  'SCSS',
  'SQL',
  'Swift',
  'TypeScript',
  'Visual Basic .NET',
  'YAML',
]

interface WorkspaceDocProps {
  workspaceName: string | undefined
  workspaceSlug: string | undefined
  workspaceCodeExample: string | undefined
  workspaceCodeLanguage: string | undefined
  tree: WorkspaceNode[] | undefined
  isEditable: boolean
  onUpdate: (obj: object) => Promise<void>
  onMove: () => void
  onDelete: () => void
}

const CodeEditor: React.ComponentType<TextareaCodeEditorProps> = dynamic(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  () =>
    import('@uiw/react-textarea-code-editor').then(
      (mod) => mod.default as React.ComponentType
    ),
  {
    ssr: false,
  }
)

export const WorkspaceDoc: React.FC<WorkspaceDocProps> = React.memo(
  ({
    workspaceName,
    workspaceSlug,
    tree,
    isEditable,
    workspaceCodeExample,
    workspaceCodeLanguage,
    onUpdate,
    onMove,
    onDelete,
  }: WorkspaceDocProps) => {
    const sortedTree = useMemo(
      () =>
        (tree || [])
          .sort((a, b) =>
            (a.name || 'Unnamed').localeCompare(b.name || 'Unnamed')
          )
          .sort((a, b) =>
            a.type === 'folder' ? -1 : b.type !== 'folder' ? 1 : 0
          ),
      [tree]
    )
    const router = useRouter()

    const [codeExample, setCodeExample] = React.useState(workspaceCodeExample)
    const [codeLanguage, _setCodeLanguage] = React.useState<string>(
      workspaceCodeLanguage || 'TypeScript'
    )
    const setCodeLanguage = useCallback(
      (value: string) => {
        _setCodeLanguage(value)
        void onUpdate({ codeLanguage: value })
      },
      [onUpdate]
    )

    const workspaceId = useMemo(() => {
      return (
        (typeof router.query.path === 'string'
          ? router.query.path
          : router.query.path?.[0]) || ''
      )
    }, [router.query.path])
    const [isEdit, setEdit] = useState(false)
    const update = useUpdate()
    const path = useMemo(
      () =>
        Array.isArray(router.query.path)
          ? router.query.path.slice(1)
          : router.query.path
          ? [router.query.path]
          : [],
      [router.query.path]
    )
    const node = useMemo(
      () =>
        tree &&
        getNodeForSlug(
          tree,
          path.length > 1 ? path[0] : undefined,
          path[path.length - 1]
        ),
      [path, tree]
    )
    useEffect(() => {
      setEdit(!!router.query.e)
      if (router.query.e)
        void router.replace((router.query.path as string[]).join('/'))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(router.query.path as string[]).join('/')])
    const updateName = useCallback(
      (name: string) => {
        const newTree = renameInTree(
          tree as WorkspaceNode[],
          node?.id as string,
          name
        )
        return update(
          'workspaces',
          workspaceId,
          {
            tree: newTree,
          },
          ['workspace', workspaceId]
        ).then(() => {
          void router.replace(
            `/${workspaceSlug || workspaceId}/${pathForId(
              newTree,
              node?.id as string
            )}`
          )
        })
      },
      [node?.id, router, tree, update, workspaceId]
    )
    const updateDoc = useCallback(
      (obj: object) =>
        update('documents', node?.id as string, obj, [
          'documents',
          node?.id as string,
        ]),
      [node?.id, update]
    )
    const { data, isLoading, error } = useDoc(node?.id)
    const breadCrumbs = useMemo((): IBreadcrumbProps[] => {
      const node1 = tree && getNodeForSlug(tree, undefined, path[0])
      if (!node1) return []
      if (path.length === 2) {
        const node2 = tree && node
        if (!node2) return []
        return [
          {
            icon: 'folder-close',
            text: node1?.name || 'Unnamed folder',
            className: 'font-bold text-sm',
          },
          {
            icon: 'document',
            text: node2?.name || 'Unnamed page',
            className: 'text-sm font-medium',
          },
        ]
      } else {
        return [
          {
            icon: 'document',
            text: node1?.name || 'Unnamed page',
            className: 'text-sm',
          },
        ]
      }
    }, [node, path, tree])

    const maybeDocs = useMemo(() => {
      return workspaceName?.toLowerCase().endsWith('docs') ? '' : 'Docs'
    }, [workspaceName])

    const copyCode = useCallback(() => {
      void window.navigator.clipboard.writeText(codeExample || '')
      AppToaster.show({
        message: 'Copied code example to clipboard',
      })
    }, [codeExample])

    if (isLoading) return <LoadingScreen />
    if (error) return <ErrorScreen message={(error as FirebaseError).message} />
    return path.length && data ? (
      <div key={data.id} className="px-6">
        <Head>
          <title>
            {breadCrumbs[breadCrumbs.length - 1].text || ''} | {workspaceName}{' '}
            {maybeDocs}
          </title>
        </Head>
        {isEdit && (
          <div className="pointer-events-none fixed inset-0 left-[320px] z-20 border-8 border-yellow-400" />
        )}
        <div className="px-2">
          <div className="pointer-events-none sticky inset-x-0 top-0 z-10 flex items-center space-x-2 py-5">
            <Breadcrumbs
              className="pointer-events-auto mr-auto rounded-xl bg-white px-2"
              items={breadCrumbs}
            />
            {isEditable ? (
              <>
                <Button
                  intent="primary"
                  active={isEdit}
                  className="pointer-events-auto px-3"
                  onClick={() => setEdit(!isEdit)}
                >
                  {isEdit ? 'Done' : 'Edit page'}
                </Button>
                <Popover2
                  className="pointer-events-auto"
                  content={
                    <Menu>
                      <MenuItem
                        icon="link"
                        text="Copy link to page"
                        onClick={() =>
                          copyLink(
                            `${workspaceSlug || workspaceId}/${path.join('/')}`
                          )
                        }
                      />
                      <MenuItem
                        icon="document-open"
                        text="Move"
                        onClick={onMove}
                      />
                      <MenuDivider />
                      <MenuItem
                        icon="trash"
                        text="Delete"
                        intent="danger"
                        onClick={onDelete}
                      />
                    </Menu>
                  }
                  position="bottom-right"
                >
                  <Button>
                    <Icon icon="more" className="px-2" />
                  </Button>
                </Popover2>
              </>
            ) : (
              <Tooltip2
                className="pointer-events-auto"
                content="Copy link to this page"
                position="left"
              >
                <Button
                  minimal
                  icon="link"
                  className="opacity-50 hover:opacity-80"
                  onClick={() =>
                    copyLink(
                      `${workspaceSlug || workspaceId}/${path.join('/')}`
                    )
                  }
                />
              </Tooltip2>
            )}
          </div>
          <div className="mx-auto max-w-screen-sm">
            <EditableText
              disabled={!isEdit}
              className="h1 mt-6 text-5xl font-bold leading-normal tracking-tight text-slate-800"
              defaultValue={node?.name || path[path.length - 1]}
              placeholder="Unnamed page"
              onConfirm={(name) => void updateName(name)}
              maxLength={64}
            />
            {(data.description || isEdit) && (
              <EditableText
                disabled={!isEdit}
                className="mt-4 text-lg leading-normal text-slate-700"
                multiline
                placeholder="Add page description"
                defaultValue={data.description}
                onConfirm={(description) => void updateDoc({ description })}
              />
            )}
            {(isEdit || codeExample) && (
              <>
                <div className="mt-12 mb-4 flex items-center px-3">
                  <h3 className="mr-auto text-xl font-bold">Code example</h3>
                  {isEdit ? (
                    <Select2
                      popoverProps={{
                        position: 'bottom-right',
                      }}
                      items={SUPPORTED_LANGUAGES}
                      itemPredicate={(query, item) =>
                        item.toLowerCase().includes(query.toLowerCase())
                      }
                      itemRenderer={(
                        item,
                        { handleClick, handleFocus, modifiers }
                      ) => (
                        <MenuItem
                          onClick={handleClick}
                          onFocus={handleFocus}
                          active={modifiers.active}
                          disabled={modifiers.disabled}
                          text={item}
                        />
                      )}
                      noResults={
                        <NonIdealState
                          icon="chat"
                          iconSize={24}
                          className="py-4"
                          description="Missing a language? Request it using the feedback button!"
                        />
                      }
                      onItemSelect={(lang) =>
                        setCodeLanguage(lang || 'TypeScript')
                      }
                    >
                      <Button
                        text={codeLanguage}
                        rightIcon="double-caret-vertical"
                      />
                    </Select2>
                  ) : (
                    <>
                      <div className="mr-3 flex items-center pr-2 text-xs font-medium !text-slate-500">
                        <Icon icon="code" size={12} className="mr-2" />
                        <div>{codeLanguage}</div>
                      </div>
                      <Button
                        minimal
                        small
                        text="Copy"
                        onClick={() => copyCode()}
                        icon="duplicate"
                        className="text-xs"
                      />
                    </>
                  )}
                </div>
                <div className="rounded-xl bg-slate-100 p-3">
                  <CodeEditor
                    disabled={!isEdit}
                    value={codeExample}
                    language={codeLanguage}
                    placeholder="Type some code..."
                    onChange={(e) => setCodeExample(e.target.value)}
                    onBlur={(e) =>
                      void onUpdate({ codeExample: e.target.value })
                    }
                    padding={15}
                    style={{
                      fontSize: 16,
                      backgroundColor: '#f1f5f9',
                      fontFamily:
                        'JetBrains Mono,ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                    }}
                  />
                </div>
              </>
            )}
            <Fields
              codeLanguage={codeLanguage}
              CodeEditor={CodeEditor}
              docId={node?.id}
              tree={sortedTree}
              fields={data.fields}
              isEdit={isEdit}
              update={(fields: Field[]) => updateDoc({ fields })}
            />
          </div>
        </div>
      </div>
    ) : (
      <NonIdealState
        description="Open a page to get started."
        icon="bookmark"
      />
    )
  }
)

WorkspaceDoc.displayName = 'WorkspaceDoc'
