import {
  Button,
  ControlGroup,
  Dialog,
  HTMLSelect,
  Icon,
  InputGroup,
  MenuDivider,
  MenuItem,
} from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import { Select2 } from '@blueprintjs/select'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import getSlug from 'speakingurl'
import { v4 } from 'uuid'
import { AppToaster } from '../components/AppToaster'
import { Field as FieldType, WorkspaceNode } from '../types'
import { copyLink, pathForId } from '../utils/utils'
import { Clickable } from './Clickable'
import { Field } from './Field'

export interface FieldsProps {
  isEdit: boolean
  fields?: FieldType[]
  tree: WorkspaceNode[]
  docId: string | undefined
  codeLanguage: string
  update: (fields: FieldType[]) => Promise<void>
  CodeEditor: React.ComponentType
}

const DEFAULT_TYPES = ['string', 'number', 'boolean']

export const Fields: React.FC<FieldsProps> = React.memo(
  ({
    isEdit,
    fields,
    tree,
    update,
    docId,
    CodeEditor,
    codeLanguage,
  }: FieldsProps) => {
    const router = useRouter()
    const [inputValue, setInputValue] = useState('')
    const [type, setType] = useState('string')
    const [typeOpen, setTypeOpen] = useState<boolean | string>(false)
    const [inputName, setInputName] = useState('')
    const [search, setSearch] = useState('')
    const [openIds, setOpenIds] = useState<string[]>([])
    const [link, setLink] = useState('')
    const fullPath = useMemo(() => {
      return (
        (Array.isArray(router.query.path)
          ? router.query.path.join('/')
          : router.query.path) || ''
      )
    }, [router.query.path])
    const sortedFields = useMemo(() => {
      return (
        fields?.sort((a, b) => {
          return (a.name || '').localeCompare(b.name || '')
        }) || []
      )
    }, [fields])

    const searchFilter = useCallback(
      (field: FieldType) => {
        return (
          (field.name || '').toLowerCase().includes(search.toLowerCase()) ||
          (field.type || '').toLowerCase().includes(search.toLowerCase())
        )
      },
      [search]
    )

    useEffect(() => {
      const slug = router.asPath.split('#')[1]
      if (fields && slug) {
        const id = fields?.find((f) => f.name && getSlug(f.name) === slug)?.id
        if (id) {
          setTimeout(() => {
            setOpenIds([id])
            void router.replace(fullPath) // Remove the hash
            const parent = document.getElementById('main')
            if (!parent) return
            const child = document.getElementById(slug)
            if (!child) return
            // Where is the parent on page
            const parentRect = parent.getBoundingClientRect()
            // What can you see?
            const parentViewableArea = {
              height: parent.clientHeight,
              width: parent.clientWidth,
            }

            // Where is the child
            const childRect = child.getBoundingClientRect()
            // Is the child viewable?
            const isViewable =
              childRect.top >= parentRect.top &&
              childRect.bottom <= parentRect.top + parentViewableArea.height

            // if you can't see the child try to scroll parent
            if (!isViewable) {
              // Should we scroll using top or bottom? Find the smaller ABS adjustment
              const scrollTop = childRect.top - parentRect.top
              const scrollBot = childRect.bottom - parentRect.bottom
              if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
                // we're near the top of the list
                parent.scrollTop += scrollTop - 100
              } else {
                // we're near the bottom of the list
                parent.scrollTop += scrollBot + 100
              }
            }
          }, 0)
        }
      }
    }, [fields, fullPath, router, router.asPath])
    const isAllExpanded = useMemo(() => {
      return openIds.length === sortedFields.length
    }, [openIds.length, sortedFields.length])

    const customTypes = useMemo(() => {
      return sortedFields.reduce((acc, field) => {
        const previouslyAddedCustomTypes = acc.map((t) => t.type)
        if (
          !DEFAULT_TYPES.includes(field.type) &&
          !previouslyAddedCustomTypes.includes(field.type)
        ) {
          acc.push({
            type: field.type,
            linkId: field.typeLinkId,
          })
        }
        return acc
      }, [] as Record<string, string | undefined>[])
    }, [sortedFields])

    const searchedFields = useMemo(
      () => sortedFields.filter(searchFilter),
      [searchFilter, sortedFields]
    )

    if ((fields || []).length === 0 && !isEdit) return null
    return (
      <div className="relative mb-96 mt-8 select-none">
        <div className="mt-12 mb-4 flex items-center space-x-3 px-3">
          <h3 className="!mr-auto text-xl font-bold">
            Fields{' '}
            <span className="text-slate-400">
              {searchedFields.length < sortedFields.length
                ? `${searchedFields.length} of ${sortedFields.length}`
                : sortedFields.length}
            </span>
          </h3>
          <InputGroup
            className="w-48"
            placeholder="Find"
            leftIcon="search"
            type="search"
            disabled={isEdit}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Tooltip2
            position="top-right"
            disabled={sortedFields.length === 0}
            content={`${isAllExpanded ? 'Collapse' : 'Expand'} all fields`}
          >
            <Button
              minimal
              disabled={sortedFields.length === 0}
              icon={isAllExpanded ? 'collapse-all' : 'expand-all'}
              onClick={() => {
                if (isAllExpanded) {
                  setOpenIds([])
                } else {
                  setOpenIds(sortedFields.map((f) => f.id))
                }
              }}
            />
          </Tooltip2>
        </div>
        <div className="grid grid-cols-1 gap-1">
          {searchedFields.map((field) => {
            const isOpen = openIds.includes(field.id)
            return (
              <Field
                customTypeIndex={customTypes
                  .map((x) => x.type)
                  .indexOf(field.type)}
                onEditType={(id) => {
                  const field = sortedFields.find((f) => f.id === id)
                  setTypeOpen(id)
                  setInputName(field?.type || '')
                  setLink(field?.typeLinkId || '')
                }}
                codeLanguage={codeLanguage}
                CodeEditor={CodeEditor}
                key={field.id}
                {...field}
                isEdit={isEdit}
                isOpen={isOpen}
                onOpen={() => {
                  const newOpenIds = [...openIds]
                  if (isOpen) {
                    newOpenIds.splice(newOpenIds.indexOf(field.id), 1)
                  } else {
                    newOpenIds.push(field.id)
                  }
                  setOpenIds(newOpenIds)
                }}
                update={(updateFunc) => update(updateFunc(sortedFields))}
                onCopyLink={() =>
                  field.name && copyLink(`${fullPath}/#${getSlug(field.name)}`)
                }
                onTypePress={(typeLinkId) =>
                  void router.push(
                    `/${router.query.path?.[0] as string}/${pathForId(
                      tree,
                      typeLinkId
                    )}`
                  )
                }
              />
            )
          })}
          {searchedFields.length < sortedFields.length && (
            <div className="rounded-xl bg-slate-100 py-4 px-5 text-center text-xs text-slate-700">
              {sortedFields.length - searchedFields.length} fields hidden.{' '}
              <Clickable onPress={() => setSearch('')}>
                <span className="cursor-pointer font-medium text-blue-500 hover:text-blue-600">
                  Clear search?
                </span>
              </Clickable>
            </div>
          )}
        </div>
        <Dialog
          isOpen={!!typeOpen}
          onClose={() => setTypeOpen(false)}
          title="Type"
          icon="add"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault()
              if (!inputName) {
                return AppToaster.show({
                  message: 'Enter a type name',
                  intent: 'danger',
                })
              }
              const arr =
                typeof typeOpen === 'string'
                  ? ([
                      ...sortedFields.filter((field) => field.id !== typeOpen),
                      {
                        ...sortedFields.find((field) => field.id === typeOpen),
                        type: inputName,
                        typeLinkId: link || null,
                      },
                    ] as FieldType[])
                  : ([
                      ...sortedFields,
                      {
                        id: v4(),
                        name: inputValue,
                        type: inputName,
                        typeLinkId: link || null,
                      },
                    ] as FieldType[])

              void update(arr).then(() => {
                setInputValue('')
                setInputName('')
                setLink('')
                setTypeOpen(false)
              })
            }}
            className="p-5 pb-0"
          >
            <p className="mb-3">
              Set a <span className="font-semibold">custom type</span> for
              arrays, objects and other data types. For example, if you store an
              array of strings, you can type{' '}
              <span className="text-mono font-semibold">string[]</span>.
            </p>
            <InputGroup
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
              large
              autoFocus
              placeholder="string[]"
              className="font-mono"
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.preventDefault()
                }
              }}
            />
            <p className="mt-2 mb-2 text-xs">
              You can type whatever you want here, as long as it&apos;s clear to
              people!
            </p>
            <div className="mt-4 font-semibold">Link to document</div>
            <p className="mb-3">
              For complex object types, you can give them a name and link them
              to a document in which you can specify every field.
            </p>
            <HTMLSelect
              large
              onChange={(e) => setLink(e.target.value || '')}
              value={link}
              className="mb-2 w-full"
            >
              <option key="" value="">
                Not linked
              </option>
              {tree
                .filter((x) => (docId ? x.id !== docId : true))
                .map((document) => (
                  <React.Fragment key={document.id}>
                    <option
                      disabled={document.type === 'folder'}
                      key={document.id}
                      value={document.id}
                    >
                      {document.name || `Unnamed ${document.type}`}
                    </option>
                    {document.childNodes
                      ?.sort((a, b) =>
                        (a.name || 'Unnamed').localeCompare(b.name || 'Unnamed')
                      )
                      .sort((a, b) =>
                        a.type === 'folder' ? -1 : b.type !== 'folder' ? 1 : 0
                      )
                      .map((child) => (
                        <option key={child.id} value={child.id}>
                          &mdash; {child.name || 'Unnamed document'}
                        </option>
                      ))}
                  </React.Fragment>
                ))}
            </HTMLSelect>
            <p className="mb-2 text-xs">
              Linking allows you to nest object types. Fun!
            </p>
            <Button
              large
              type="submit"
              text="Set type"
              intent="primary"
              className="mt-4 w-full text-center"
            />
          </form>
        </Dialog>
        {isEdit && (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              if (!inputValue) {
                return AppToaster.show({
                  message: 'Enter a field name first',
                  intent: 'danger',
                })
              }
              if (type === 'Type') {
                setTypeOpen(true)
              } else {
                const obj = { id: v4(), name: inputValue, type } as FieldType
                if (link) obj.typeLinkId = link
                void update([...sortedFields, obj]).then(() => {
                  setInputValue('')
                })
              }
            }}
          >
            <ControlGroup className="mt-4 rounded-xl border p-4">
              <InputGroup
                placeholder={'Add field'}
                onKeyDown={(e) => {
                  if (e.key === ' ') {
                    e.preventDefault()
                  }
                }}
                large
                fill
                className="text-mono"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <Select2
                items={[...DEFAULT_TYPES, '-', 'Type', ...customTypes]}
                itemRenderer={(value, props) => {
                  let type, linkId
                  if (typeof value === 'object') {
                    type = value.type
                    linkId = value.linkId
                  } else {
                    type = value
                  }
                  return value === '-' ? (
                    <MenuDivider />
                  ) : (
                    <MenuItem
                      onClick={props.handleClick}
                      onFocus={props.handleFocus}
                      {...props.modifiers}
                      text={
                        <span className="flex items-center justify-between">
                          {type} {linkId && <Icon icon="link" size={12} />}
                        </span>
                      }
                      className={type === 'Type' ? 'font-medium' : 'text-mono'}
                      icon={type === 'Type' ? 'add' : undefined}
                    />
                  )
                }}
                activeItem={type}
                filterable={false}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={(value) => {
                  setType(
                    (typeof value === 'string' ? value : value.type) || ''
                  )
                  if (typeof value !== 'string' && value.linkId)
                    setLink(value.linkId)
                }}
              >
                <Button
                  text={type}
                  icon={type === 'Type' ? 'add' : undefined}
                  className="flex h-10 w-48 self-stretch font-mono"
                  rightIcon={
                    <Icon icon="double-caret-vertical" className="ml-auto" />
                  }
                />
              </Select2>
              <Button
                type="submit"
                text="Add"
                className="px-4"
                intent="primary"
              />
            </ControlGroup>
          </form>
        )}
      </div>
    )
  }
)

Fields.displayName = 'Fields'
