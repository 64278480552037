import { getAuth } from 'firebase/auth'
import { collection, doc, setDoc } from 'firebase/firestore'
import { useCallback } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { db } from '../pages/_app'

export function useCreate() {
  return useCallback(async (collectionName: string, data: object) => {
    const ref = doc(collection(db, collectionName))
    return setDoc(ref, data)
  }, [])
}

export function useCreateDoc() {
  const auth = getAuth()
  const [user] = useAuthState(auth)
  return useCallback(async () => {
    if (!user) return
    const ref = doc(collection(db, 'documents'))
    await setDoc(ref, {
      workspaceId: user.uid,
    })
    return ref.id
  }, [user])
}
