import { TreeNodeInfo } from '@blueprintjs/core'
import getSlug from 'speakingurl'
import { AppToaster } from '../components/AppToaster'
import { WorkspaceNode } from '../types'

export function getCollapsedFolderIds(): string[] {
  const collapsedFoldersDoc =
    typeof window !== 'undefined' &&
    window.localStorage.getItem(`whatfields-collapsed-folders`)
  let collapsedFolderIds: string[] | undefined
  try {
    const c = JSON.parse(collapsedFoldersDoc || '[]') as unknown
    if (Array.isArray(c) && c.length > 0) {
      collapsedFolderIds = c
    }
  } catch (e) {
    // Do nothing.
  }
  return collapsedFolderIds || []
}

export function mapTree(
  path: string[],
  tree: WorkspaceNode[],
  collapsedFolderIds: string[],
  topName?: string,
  topId?: string
): TreeNodeInfo[] | undefined {
  return tree
    ?.map((item) => mapNode(path, item, collapsedFolderIds, topName, topId))
    .sort((a, b) =>
      ((a.label as string) || 'Unnamed').localeCompare(
        (b.label as string) || 'Unnamed'
      )
    )
    .sort((a, b) =>
      a.icon === 'folder-close' ? -1 : b.icon !== 'folder-close' ? 1 : 0
    )
}

export function isSelected(
  path: string[],
  item: WorkspaceNode,
  topName?: string,
  topId?: string
) {
  const documentMatch = path[1]
    ? path[1] === (item.name ? getSlug(item.name) : item.id)
    : path[0] === (item.name ? getSlug(item.name) : item.id)
  const folderMatch = path[1]
    ? path[0] === (topName ? getSlug(topName) : topId)
    : !topName
  return folderMatch && documentMatch
}

export function copyLink(path: string, message?: string) {
  const link = `${new URL(window.location.href).origin}/${path}`
  void window.navigator.clipboard.writeText(link)
  AppToaster.show({
    action: {
      href: link,
      target: '_blank',
      text: 'Open',
    },
    message: message || 'Copied link to clipboard',
  })
}

export function mapNode(
  path: string[],
  item: WorkspaceNode,
  collapsedFolderIds: string[],
  topName?: string,
  topId?: string
): TreeNodeInfo {
  return {
    id: item.id,
    label: item.name,
    icon: item.type === 'folder' ? 'folder-close' : 'document',
    className: item.type === 'folder' ? 'font-bold' : 'font-medium',
    hasCaret: item.type === 'folder',
    nodeData: {
      topName,
      topId,
    },
    isExpanded: item.childNodes
      ? !(collapsedFolderIds || []).includes(item.id)
      : undefined,
    isSelected:
      item.type === 'document' && isSelected(path, item, topName, topId),
    childNodes: item.childNodes
      ? mapTree(path, item.childNodes, collapsedFolderIds, item.name, item.id)
      : undefined,
  } as unknown as TreeNodeInfo
}

export function addToTree(
  tree: WorkspaceNode[] | undefined,
  type: 'document' | 'folder',
  id: string,
  name: string,
  folderId: string
): WorkspaceNode[] {
  const newTree = [...(tree || [])]
  const obj = {
    id,
    name,
    type,
  }
  if (folderId) {
    const folderIndex = newTree.findIndex(
      (node) => node.type === 'folder' && node.id === folderId
    )
    if (folderIndex < 0) throw new Error('Folder not found')
    newTree[folderIndex] = {
      ...newTree[folderIndex],
      childNodes: [...(newTree[folderIndex].childNodes || []), obj],
    }
  } else {
    // Push to top level
    newTree.push(obj)
  }
  return newTree
}

export function findInTree(tree: WorkspaceNode[], id: string) {
  let index = -1
  let childIndex = -1
  let i = 0
  let j = 0
  for (const node of tree) {
    if (node.id === id) {
      index = i
      break
    }
    if (node.childNodes) {
      j = 0
      for (const childNode of node.childNodes) {
        if (childNode.id === id) {
          index = i
          childIndex = j
          break
        }
        j++
      }
    }
    i++
  }
  return { index, childIndex }
}

export function renameInTree(
  tree: WorkspaceNode[],
  id: string,
  newName: string
) {
  const { index, childIndex } = findInTree(tree, id)
  if (index < 0) throw new Error('File not found')

  const newTree = [...(tree || [])]
  if (childIndex > -1) {
    const childNodes = [...(newTree[index].childNodes as WorkspaceNode[])]
    childNodes[childIndex] = {
      ...childNodes[childIndex],
      name: newName,
    }
    newTree[index] = {
      ...newTree[index],
      childNodes,
    }
  } else {
    newTree[index] = {
      ...newTree[index],
      name: newName,
    }
  }
  return newTree
}

export function removeFromTree(tree: WorkspaceNode[], id: string) {
  const { index, childIndex } = findInTree(tree, id)
  if (index < 0) throw new Error('File not found')
  const newTree = JSON.parse(JSON.stringify(tree || [])) as WorkspaceNode[]
  if (childIndex > -1) {
    newTree[index].childNodes?.splice(childIndex, 1)
  } else {
    newTree.splice(index, 1)
  }
  return newTree
}

export function pathForId(tree: WorkspaceNode[], id: string) {
  const { index, childIndex } = findInTree(tree, id)
  const firstSlug =
    typeof tree[index].name === 'string'
      ? getSlug(tree[index].name as string)
      : tree[index].id
  if (childIndex === -1) {
    return firstSlug
  } else {
    const secondSlug =
      typeof tree[index].childNodes?.[childIndex].name === 'string'
        ? getSlug(tree[index].childNodes?.[childIndex].name as string)
        : tree[index].childNodes?.[childIndex].id
    return `${firstSlug}/${secondSlug as string}`
  }
}

export function getNodeForSlug(
  tree: WorkspaceNode[],
  folder: string | undefined,
  document: string
) {
  for (const node of tree) {
    const nodeSlug = node.name ? getSlug(node.name) : node.id
    if (!folder && document === nodeSlug) {
      return node
    }
    if (folder && nodeSlug === folder) {
      if (node.childNodes) {
        for (const childNode of node.childNodes) {
          const childNodeSlug = childNode.name
            ? getSlug(childNode.name)
            : childNode.id
          if (document === childNodeSlug) {
            return childNode
          }
        }
      }
    }
  }
  return undefined
}

export function moveInTree(
  tree: WorkspaceNode[],
  id: string,
  folderId: string
) {
  const { index, childIndex } = findInTree(tree, id)
  if (index < 0) throw new Error('File not found')

  const newTree = JSON.parse(JSON.stringify(tree || [])) as WorkspaceNode[]
  if (childIndex > -1) {
    // Case 1: folder to top level
    // Remove from childNodes and add to tree
    if (!folderId) {
      newTree[index].childNodes?.splice(childIndex, 1)
      newTree.push({
        ...(tree[index].childNodes?.[childIndex] as WorkspaceNode),
      })
    } else {
      // Case 2: folder to folder
      // Remove from childNodes and add to childNodes
      newTree[index].childNodes?.splice(childIndex, 1)
      const newIndex = tree.findIndex(
        (node) => node.type === 'folder' && node.id === folderId
      )
      if (newIndex < 0) throw new Error('Folder not found')
      newTree[newIndex].childNodes = [
        ...(newTree[newIndex].childNodes || []),
        tree[index].childNodes?.[childIndex] as WorkspaceNode,
      ]
    }
  } else if (folderId) {
    // Case 3: top level to folder
    // Remove from tree and add to childNodes
    const oldNode = { ...newTree[index] }
    newTree.splice(index, 1)
    const newIndex = newTree.findIndex(
      (node) => node.type === 'folder' && node.id === folderId
    )
    if (newIndex < 0) throw new Error('Folder not found')
    newTree[newIndex].childNodes = [
      ...(newTree[newIndex].childNodes || []),
      oldNode,
    ]
  }
  return newTree
}
