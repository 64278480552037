import { EditableText, Button, Icon } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import { TextareaCodeEditorProps } from '@uiw/react-textarea-code-editor'
import { motion } from 'framer-motion'
import React, { useCallback, useMemo } from 'react'
import getSlug from 'speakingurl'
import { AppToaster } from '../components/AppToaster'
import { Field as FieldType } from '../types'
import { Clickable } from './Clickable'

type FieldProps = FieldType & {
  isEdit: boolean
  update: (updateFunc: (allFields: FieldType[]) => FieldType[]) => Promise<void>
  onTypePress: (typeLinkId: string) => void
  onCopyLink: () => void
  onEditType: (id: string) => void
  onOpen: (id: string) => void
  isOpen: boolean
  codeLanguage: string
  customTypeIndex: number
  CodeEditor: React.ComponentType<TextareaCodeEditorProps>
}

export const Field: React.FC<FieldProps> = React.memo((field: FieldProps) => {
  const {
    name,
    id,
    type,
    description,
    warning,
    details,
    defaultValue,
    isEdit,
    isOpen,
    onOpen,
    onEditType,
    update,
    customTypeIndex,
    CodeEditor,
    codeExample: remoteCodeExample,
    codeLanguage,
    typeLinkId,
    onTypePress,
    onCopyLink,
  } = field
  const slug = useMemo(() => (name ? getSlug(name) : ''), [name])
  const getUpdatableFields = useCallback(() => {
    const obj = {
      id,
      name,
      type,
      typeLinkId,
      description,
      warning,
      details,
      defaultValue,
      codeExample: remoteCodeExample,
    }
    Object.keys(obj).forEach((key) =>
      obj[key as keyof FieldType] === undefined
        ? delete obj[key as keyof FieldType]
        : {}
    )
    return obj
  }, [
    id,
    remoteCodeExample,
    defaultValue,
    description,
    details,
    name,
    type,
    typeLinkId,
    warning,
  ])

  const [codeExample, setCodeExample] = React.useState(remoteCodeExample)
  const typeIndex = useMemo(() => {
    // We only have 7 colors so the 8th should use the 1st again.
    let index = customTypeIndex
    while (index > 6) {
      index -= 7
    }
    return index
  }, [customTypeIndex])

  const classForTitleColor = useMemo(() => {
    switch (type) {
      case 'string':
        return 'text-blue-900'
      case 'number':
        return 'text-emerald-900'
      case 'boolean':
        return 'text-rose-900'
      default:
        return [
          'text-purple-900',
          'text-orange-900',
          'text-lime-900',
          'text-amber-900',
          'text-stone-900',
          'text-cyan-900',
          'text-yellow-900',
        ][typeIndex]
    }
  }, [type, typeIndex])

  const classForTitleHover = useMemo(() => {
    switch (type) {
      case 'string':
        return 'hover:text-blue-700'
      case 'number':
        return 'hover:text-emerald-700'
      case 'boolean':
        return 'hover:text-rose-700'
      default:
        return [
          'hover:text-purple-700',
          'hover:text-orange-700',
          'hover:text-lime-700',
          'hover:text-amber-700',
          'hover:text-stone-700',
          'hover:text-cyan-700',
          'hover:text-yellow-700',
        ][typeIndex]
    }
  }, [type, typeIndex])

  const classForCodeBg = useMemo(() => {
    switch (type) {
      case 'string':
        return 'bg-blue-200'
      case 'number':
        return 'bg-emerald-200'
      case 'boolean':
        return 'bg-rose-200'
      default:
        return [
          'bg-purple-200',
          'bg-orange-200',
          'bg-lime-200',
          'bg-amber-200',
          'bg-stone-200',
          'bg-cyan-200',
          'bg-yellow-200',
        ][typeIndex]
    }
  }, [type, typeIndex])

  const hexForCodeBg = useMemo(() => {
    // 200
    switch (type) {
      case 'string':
        return '#bfdbfe'
      case 'number':
        return '#a7f3d0'
      case 'boolean':
        return '#fecdd3'
      default:
        return [
          '#e9d5ff',
          '#fed7aa',
          '#d9f99d',
          '#fde68a',
          '#e7e5e4',
          '#a5f3fc',
          '#fef08a',
        ][typeIndex]
    }
  }, [type, typeIndex])

  const classForDescriptionColor = useMemo(() => {
    switch (type) {
      case 'string':
        return '!text-blue-700'
      case 'number':
        return '!text-emerald-700'
      case 'boolean':
        return '!text-rose-700'
      default:
        return [
          '!text-purple-700',
          '!text-orange-700',
          '!text-lime-700',
          '!text-amber-700',
          '!text-stone-700',
          '!text-cyan-700',
          '!text-yellow-700',
        ][typeIndex]
    }
  }, [type, typeIndex])

  const classForSubtitleColor = useMemo(() => {
    switch (type) {
      case 'string':
        return '!text-blue-500'
      case 'number':
        return '!text-emerald-500'
      case 'boolean':
        return '!text-rose-500'
      default:
        return [
          '!text-purple-500',
          '!text-orange-500',
          '!text-lime-500',
          '!text-amber-500',
          '!text-stone-500',
          '!text-cyan-500',
          '!text-yellow-500',
        ][typeIndex]
    }
  }, [type, typeIndex])

  const classForBorder = useMemo(() => {
    switch (type) {
      case 'string':
        return 'border-blue-200'
      case 'number':
        return 'border-emerald-200'
      case 'boolean':
        return 'border-rose-200'
      default:
        return [
          'border-purple-200',
          'border-orange-200',
          'border-lime-200',
          'border-amber-200',
          'border-stone-200',
          'border-cyan-200',
          'border-yellow-200',
        ][typeIndex]
    }
  }, [type, typeIndex])

  const classForBg = useMemo(() => {
    switch (type) {
      case 'string':
        return 'bg-blue-100'
      case 'number':
        return 'bg-emerald-100'
      case 'boolean':
        return 'bg-rose-100'
      default:
        return [
          'bg-purple-100',
          'bg-orange-100',
          'bg-lime-100',
          'bg-amber-100',
          'bg-stone-100',
          'bg-cyan-100',
          'bg-yellow-100',
        ][typeIndex]
    }
  }, [type, typeIndex])

  const textForType = useMemo(() => {
    switch (type) {
      case 'string':
        return 'ABC'
      case 'number':
        return '123'
      case 'boolean':
        return 'I/O'
      default:
        return '{ }'
    }
  }, [type])

  const hasExtraFields = useMemo(() => {
    return (
      description ||
      warning ||
      details ||
      defaultValue ||
      remoteCodeExample ||
      isEdit
    )
  }, [defaultValue, description, details, remoteCodeExample, warning, isEdit])

  const copyCode = useCallback(() => {
    void window.navigator.clipboard.writeText(codeExample || '')
    AppToaster.show({
      message: 'Copied code example to clipboard',
    })
  }, [codeExample])

  const copyTitle = useCallback(() => {
    void window.navigator.clipboard.writeText(name || '')
    AppToaster.show({
      message: (
        <>
          Copied{' '}
          <span
            className={`font-mono font-semibold ${classForDescriptionColor}`}
          >
            {name || ''}
          </span>{' '}
          to clipboard
        </>
      ),
    })
  }, [classForDescriptionColor, name])

  return (
    <motion.div
      id={slug}
      key={id}
      onClick={() => !isOpen && onOpen(id)}
      className={`transform overflow-hidden rounded-xl transition duration-200 ${
        hasExtraFields && !isOpen ? 'cursor-pointer hover:scale-[101%]' : ''
      } ${isOpen && hasExtraFields ? '!my-3 -mx-6' : ''} ${classForBg}`}
    >
      <motion.div className="group flex items-center px-5">
        <motion.div
          className={`${classForDescriptionColor} text-mono mt-px w-10 flex-shrink-0 text-xs`}
        >
          {textForType}
        </motion.div>
        {isEdit && isOpen ? (
          <EditableText
            placeholder="Unnamed field"
            className={`${classForTitleColor} text-mono flex-shrink-0 text-base font-bold`}
            onConfirm={(name) =>
              void update((allFields) => [
                ...allFields.filter((x) => x.id !== id),
                {
                  ...getUpdatableFields(),
                  name,
                },
              ])
            }
            defaultValue={name}
          />
        ) : (
          <Clickable
            onPress={
              !isEdit && (isOpen || !hasExtraFields) ? copyTitle : undefined
            }
          >
            <motion.div
              className={`${classForTitleColor} ${
                !isEdit && (isOpen || !hasExtraFields)
                  ? `${classForTitleHover}`
                  : ''
              } text-mono flex-shrink-0 text-base font-bold`}
            >
              {name || ''}
            </motion.div>
          </Clickable>
        )}
        <motion.div
          className={`${
            hasExtraFields ? 'cursor-pointer' : ''
          } mx-3 flex h-12 flex-1 items-center self-stretch overflow-hidden`}
          onClick={() => isOpen && onOpen(id)}
        >
          {(!isOpen || (isOpen && !hasExtraFields)) && (
            <p
              className={`${classForDescriptionColor} mx-2 -mt-0.5 flex-1 truncate`}
            >
              {field.description}
            </p>
          )}
          {hasExtraFields && !isOpen && (
            <Icon
              icon="chevron-down"
              className={`${classForDescriptionColor} mr-3`}
            />
          )}
          {hasExtraFields && isOpen && (
            <Icon
              icon="chevron-up"
              className={`${classForDescriptionColor} ml-auto mr-3`}
            />
          )}
        </motion.div>
        <motion.div
          role={
            typeLinkId && (isOpen || !hasExtraFields) ? 'button' : undefined
          }
          onClick={() => {
            return isEdit
              ? onEditType(id)
              : typeLinkId && (isOpen || !hasExtraFields)
              ? onTypePress(typeLinkId)
              : undefined
          }}
          className={`select-none rounded ${
            (typeLinkId && (isOpen || !hasExtraFields)) || isEdit
              ? 'cursor-pointer hover:bg-white/90'
              : ''
          } bg-white/70 px-2.5 py-1 font-medium ${classForDescriptionColor}`}
        >
          {field.type}
          {typeLinkId ? (
            <span className={`pl-1 ${classForSubtitleColor}`}>↗</span>
          ) : null}
        </motion.div>
        {isEdit ? (
          <Button
            onClick={() =>
              void update(
                (allFields) => allFields?.filter((f) => f.id !== field.id) || []
              )
            }
            className={`ml-3 ${classForSubtitleColor}`}
            minimal
            icon="trash"
          />
        ) : (
          isOpen &&
          hasExtraFields && (
            <Tooltip2
              content="Copy link to this field"
              position="top-right"
              className="ml-3"
            >
              <Button
                onClick={() => onCopyLink()}
                minimal
                className={classForDescriptionColor}
                icon="link"
              />
            </Tooltip2>
          )
        )}
      </motion.div>
      {isOpen && (hasExtraFields || isEdit) && (
        <motion.div className={`${classForBorder} border-t`}>
          <div className="px-7 pt-5 pb-7">
            <div className="space-y-6">
              {(isEdit || field.description) && (
                <div>
                  {isEdit && (
                    <div className="flex h-7 items-center">
                      <h4 className={`${classForSubtitleColor} flex-1`}>
                        Description
                      </h4>
                    </div>
                  )}
                  {isEdit ? (
                    <EditableText
                      className={`${classForDescriptionColor} text-sm leading-normal`}
                      defaultValue={field.description}
                      onConfirm={(description) =>
                        void update((allFields) => [
                          ...allFields.filter((x) => x.id !== field.id),
                          { ...getUpdatableFields(), description },
                        ])
                      }
                      multiline
                      placeholder="Add field description"
                    />
                  ) : field.description ? (
                    <p
                      className={`${classForDescriptionColor} max-w-xl text-base leading-normal`}
                    >
                      {field.description}
                    </p>
                  ) : null}
                </div>
              )}

              {(isEdit || field.defaultValue) && (
                <div>
                  <div className="flex h-7 items-center">
                    <h4 className={`${classForSubtitleColor} flex-1`}>
                      Default value
                    </h4>
                  </div>
                  {isEdit ? (
                    <EditableText
                      className={`${classForDescriptionColor} text-sm leading-normal`}
                      defaultValue={field.defaultValue}
                      onConfirm={(defaultValue) =>
                        void update((allFields) => [
                          ...allFields.filter((x) => x.id !== field.id),
                          { ...getUpdatableFields(), defaultValue },
                        ])
                      }
                      multiline
                      placeholder="Add default value"
                    />
                  ) : field.defaultValue ? (
                    <p
                      className={`${classForDescriptionColor} text-sm leading-normal`}
                    >
                      {field.defaultValue}
                    </p>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          {(isEdit || codeExample) && (
            <div className={`${classForCodeBg} h-full`}>
              <div className={`flex items-center space-x-2 px-7 pt-5`}>
                <h4 className={`${classForSubtitleColor} flex-1`}>
                  Code example
                </h4>
                <Button
                  minimal
                  small
                  disabled={isEdit}
                  intent="primary"
                  text="Copy"
                  onClick={() => copyCode()}
                  icon="duplicate"
                  className={`text-xs ${
                    isEdit ? 'opacity-50' : ''
                  } ${classForDescriptionColor}`}
                />
              </div>
              <div className={`${classForCodeBg}`}>
                <CodeEditor
                  disabled={!isEdit}
                  value={codeExample}
                  language={codeLanguage}
                  placeholder="Type some code"
                  onChange={(e) => setCodeExample(e.target.value)}
                  onBlur={(e) =>
                    void update((allFields) => [
                      ...allFields.filter((x) => x.id !== field.id),
                      {
                        ...getUpdatableFields(),
                        codeExample: e.target.value,
                      },
                    ])
                  }
                  padding={28}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'auto',
                    fontSize: 14,
                    backgroundColor: hexForCodeBg,
                    fontFamily:
                      'JetBrains Mono,ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                  }}
                />
              </div>
              <Tooltip2
                disabled={!isEdit}
                className={`mb-5 ${isEdit ? 'cursor-help' : ''}`}
                content={
                  <span className="text-xs">
                    You can change the language in the big code example above.
                  </span>
                }
              >
                <div
                  className={`flex items-center px-7 text-xs font-medium ${classForSubtitleColor}`}
                >
                  <Icon icon="code" size={12} className="mr-2" />
                  <div>{codeLanguage}</div>
                </div>
              </Tooltip2>
            </div>
          )}
        </motion.div>
      )}
    </motion.div>
  )
})

Field.displayName = 'Field'
